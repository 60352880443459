import { lazy, ReactElement, Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from '../../auth/guard';
// layouts
import DashboardLayout from '../../../layouts/dashboard';
// components
import { LoadingScreen } from '../../shared/components/loading-screen';

// ----------------------------------------------------------------------

const UserList = lazy(() => import('../features/userList/UserListView'));

// ----------------------------------------------------------------------

export const userRoutes = [
  {
    path: 'user',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'list',
        element: <UserList />,
      },
    ],
  },
];
