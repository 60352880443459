import { useNavigate } from 'react-router';

import React from 'react';
import { paths } from '../../shared/routes/paths';
import { useAppSelector } from '../../shared/store';

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate(paths.auth.jwt.login);
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
}
