import { TextField } from '@mui/material';
import CreateSkillCategory from './CreateSkillCategory';

const CreateCategoryModal = ({ data, setData }: any) => {
  const handleChangeCategoryName = (e: any) => {
    setData((prevData: any) => ({
      ...prevData,
      name: e.target.value,
    }));
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-6">
        <div className="border rounded-lg">
          <div className="p-10 pb-6 flex justify-center items-center gap-6">
            <TextField
              label="Category Name"
              variant="outlined"
              sx={{ width: '100%' }}
              onChange={(e) => handleChangeCategoryName(e)}
              value={data?.name}
            />
          </div>

          <div className="mb-10 mx-10 bg-[#F9FAFB] py-6 px-3 rounded-lg">
            <CreateSkillCategory data={data} setData={setData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategoryModal;
