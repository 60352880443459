// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PROJECT: '/project',
  USER: '/user',
  PROFILE: '/profile',
  KPI: '/kpi',
  TEMPLATE: '/template',
  EVALUATION: '/evaluation',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    createProject: `${ROOTS.DASHBOARD}/project/list/create`,
    createUser: `${ROOTS.DASHBOARD}/user/list/create`,
  },
  project: {
    project: `${ROOTS.PROJECT}/list`,
    modal: `${ROOTS.PROJECT}/list/create`,
    create: `${ROOTS.PROJECT}/create`,
    edit: (id: string) => `${ROOTS.PROJECT}/${id}/edit`,
  },
  user: {
    user: `${ROOTS.USER}/list`,
    modal: `${ROOTS.USER}/list/create`,
    create: `${ROOTS.USER}/create`,
    edit: (id: string) => `${ROOTS.USER}/${id}/edit`,
  },
  profile: {
    profile: `${ROOTS.PROFILE}/edit`,
  },
  kpi: {
    kpi: `${ROOTS.KPI}`,
    templates: `${ROOTS.TEMPLATE}`,
    // evaluation: `${ROOTS.EVALUATION}`,
  },
};
