// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column, KanbanData } from './types';
import { deleteColumne, fetchKanban } from './thunks';
import { useAppDispatch } from 'src/modules/shared/store';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

//import { moveColumn } from 'src/api/kanban';

export interface AuthState {
  status: string;
  board: KanbanData | any | null;
  error: string | null;
  isLoading: boolean;
  isEdited: any;
  tasks: any;
}

const initialState: AuthState = {
  status: 'idle',
  board: null,
  isEdited: 0,
  error: null,
  isLoading: false,
  tasks: null,
};

const sortColumns = (board: KanbanData) => {
  const sortedColumns: Record<string, Column> = {};
  board.ordered.forEach((columnId) => {
    const column = board.columns[columnId];
    sortedColumns[columnId] = column;
  });
  return sortedColumns;
};

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    updateBoard: (state, action: PayloadAction<any>) => {
      state.isEdited = Number(state.isEdited) + 1;
      let currentTasks = current(state.board.tasks);
      let tasktoArrray = Object.values(currentTasks).sort((a: any, b: any) => a.order - b.order);
      state.tasks = tasktoArrray.map((board: any) => {
        return board.id == action.payload.target
          ? { ...board, status: action.payload.status }
          : board;
      });
    },
    addToBoard: (state, action: PayloadAction<any>) => {

      const currentTasks = current(!state.tasks ? state.board.tasks : state.tasks);

      const taskToArray = Object.values(currentTasks).sort((a: any, b: any) => a.order - b.order);

      const newTask = {
        ...action.payload,
        status: action.payload.status.name,
        labels: action.payload.department,
        assignee: action.payload.members,
      };
      const newArray = [...taskToArray, newTask];


      state.tasks = newArray;
    },

    deleteColumn: (state, action: PayloadAction<string>) => {
      const columnIdToDelete = action.payload;
      if (state.board) {
        // Remove the column from the columns object
        delete state.board.columns[columnIdToDelete];
        // Remove the columnId from the ordered array
        state.board.ordered = state.board.ordered.filter((id) => id !== columnIdToDelete);
      }
    },
    addColumn: (
      state,
      action: PayloadAction<{ id: string; name: string; taskIds: string[]; color: string }>
    ) => {
      const { id, name, taskIds, color } = action.payload;

      // Ensure state.board is available
      if (state.board) {
        // Add the new column to the columns object
        state.board.columns[id] = {
          id,
          name,
          taskIds,
          color,
        };

        // Add the new column id to the ordered array
        state.board.ordered.push(id);
      }
    },

    moveProject: (
      state,
      action: PayloadAction<{ taskId: string; sourceColumnId: string; targetColumnId: string }>
    ) => {
      const { taskId, sourceColumnId, targetColumnId } = action.payload;

      if (
        state.board &&
        state.board.columns[sourceColumnId] &&
        state.board.columns[targetColumnId]
      ) {
        state.board.columns[sourceColumnId].taskIds = state.board.columns[
          sourceColumnId
        ].taskIds.filter((task) => task.id !== taskId);

        state.board.columns[targetColumnId].taskIds.push({ id: taskId });
      }
    },
    moveColumn: (
      state,
      action: PayloadAction<{ sourceIndex: string; destinationIndex: number }>
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;

      // Ensure columns and ordered array are available
      const columns = { ...state.board?.columns };
      const ordered = [...state.board?.ordered];

      if (columns && ordered) {
        // Remove the column from the source index
        const [movedColumn] = ordered.splice(sourceIndex, 1);

        // Insert the column into the destination index
        ordered.splice(destinationIndex, 0, movedColumn);

        // Update the columns in the board
        state.board.columns = columns;
        state.board.ordered = ordered;
      }
    },
    // moveColumn: (state, action: PayloadAction<{ sourceColumnId: string; targetIndex: number }>) => {
    //   const { sourceColumnId, targetIndex } = action.payload;
    //   if (state.board) {
    //     const index = state.board.ordered.indexOf(sourceColumnId);
    //     if (index > -1) {
    //       state.board.ordered.splice(index, 1);
    //       state.board.ordered.splice(targetIndex, 0, sourceColumnId);
    //     }
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKanban.pending, (state) => {
      state.error = null;
      state.status = 'loading';
      state.isLoading = true;
    });
    builder.addCase(fetchKanban.fulfilled, (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.board = {
        ...payload,
        columns: sortColumns(payload),
      };

      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchKanban.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
      state.isLoading = false;
    });
  },
});

export const moveProject = kanbanSlice.actions.moveProject;
const moveColumn = kanbanSlice.actions.moveColumn;
const addColumn = kanbanSlice.actions.addColumn;
const deleteColumn = kanbanSlice.actions.deleteColumn;
const updateBoard = kanbanSlice.actions.updateBoard;
const addToBoard = kanbanSlice.actions.addToBoard;
export { moveColumn, addColumn, deleteColumn, addToBoard, updateBoard };

export default kanbanSlice.reducer;
