/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../modules/shared/utils/axios';

export const createComment = createAsyncThunk(
  'comments/createComment',
  async (payload: any, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/comments/`, payload);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchCommentByProject = createAsyncThunk(
  'comments/fetchCommentByProject',
  async ({ projectId,pageSize,page }: {projectId:string,pageSize?:number,page?:number}, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/comments/project/${projectId}?page_size=${pageSize || 12}&page=${page || 1}`);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchCommentByProjectInfiniteScroll = createAsyncThunk(
  'comments/fetchCommentByProjectInfiniteScroll',
  async ({ projectId,pageSize,page }: {projectId:string,pageSize?:number,page?:number}, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/comments/project/${projectId}?page_size=${pageSize || 12}&page=${page || 1}`);
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);


export const postFiles = createAsyncThunk(
  'comments/postFiles',
  async (payload:any, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/files/`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data' 
        }
      });
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }
      // throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);



