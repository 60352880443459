/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTemplateById, getTemplates } from './templateThunk';

export interface AuthState {
  status: string;
  templates: any;
  templatesMetadata: any;
  template: any;
  error: string | null;
}

const initialState: AuthState = {
  status: 'idle',
  templates: null,
  templatesMetadata: null,
  template: null,
  error: null,
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTemplates.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.results;
      state.templatesMetadata = action.payload.count;
      state.status = 'succeeded';
    });
    builder.addCase(getTemplates.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(getTemplateById.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getTemplateById.fulfilled, (state, action) => {
      state.template = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(getTemplateById.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
  },
});

export const {} = templatesSlice.actions;

export default templatesSlice.reducer;
