import { useMemo } from 'react';
// routes
import { paths } from 'src/modules/shared/routes/paths';
// components
import SvgColor from 'src/modules/shared/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  overview: icon('activity'),
  layers: icon('layers-three-01'),
  users: icon('users-01'),
  kpi: icon('kpi'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'General',
        items: [
          { title: 'Overview', path: paths.dashboard.root, icon: ICONS.overview },
          { title: 'projects', path: paths.project.project, icon: ICONS.layers },
          { title: 'members', path: paths.user.user, icon: ICONS.users },
          {
            title: 'Evaluation',
            path: paths.kpi.kpi,
            icon: ICONS.kpi,
            children: [
              { title: 'KPI', path: paths.kpi.kpi },
              { title: 'Template', path: paths.kpi.templates },
              // { title: 'Evaluation', path: paths.kpi.evaluation },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
