import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance, { publicInstance } from 'src/modules/shared/utils/axios';
import { ITemplate } from './templateType';

export const getEvaluationProject = createAsyncThunk(
  'getEvaluationProject',
  async (query: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${query?.token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await publicInstance.get(`/projects/${query?.id}`, config);

      if (response.status === 200) {
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const getEvaluationTemplate = createAsyncThunk(
  'getEvaluationTemplate',
  async (query: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${query?.token}`,
          'Content-Type': 'application/json',
        },
      };

      const templateResponse = await publicInstance.get(`/templates/${query?.id}/`, config);
      const categoriesResponse = await publicInstance.get(
        `/categories/${query?.id}/template`,
        config
      );

      const template: ITemplate = {
        id: templateResponse.data.id,
        name: templateResponse.data.name,
        note: String(templateResponse.data.max_score),
        categories: categoriesResponse.data,
      };

      if (templateResponse.status === 200 && categoriesResponse.status === 200) {
        return template;
      }

      throw new Error(templateResponse.statusText);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const createEvaluationProject = createAsyncThunk(
  'createEvaluationProject',
  async (query: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${query?.token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await publicInstance.patch(`/user_criteria/`, query?.data, config);

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const getUserEvaluationById = createAsyncThunk(
  'getUserEvaluationById',
  async (query: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${query?.token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await publicInstance.get(
        `/user_criteria/${query?.user_id}/${query?.project_id}`,
        config
      );

      if (response.status === 200) {
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);
