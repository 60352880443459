/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDepartments, fetchOneDepartment } from './departmentsThunk';

export interface AuthState {
  status: string;
  departments: [] | null;
  error: string | null;
  department: {
    id: string | null;
    name: string | null;
    members: [];
    color: string | null;
  } | null;
}

const initialState: AuthState = {
  status: 'idle',
  departments: [],
  department: null,
  error: null,
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.departments = [];
    },
    restoreDepartment: (state) => {
      state.department = initialState.department;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchDepartments.fulfilled, (state, action: PayloadAction<any>) => {
      state.departments = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchDepartments.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(fetchOneDepartment.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchOneDepartment.fulfilled, (state, action: PayloadAction<any>) => {
      state.department = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchOneDepartment.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
  },
});

export const { initialise, restoreDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
