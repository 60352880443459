import { sumCoefficient } from '../../utils/sumCoefficient';
import CustomPublicTableCard from './CustomPublicTableCard';

const PreviewPublic = ({ data }: any) => {
  return (
    <div className="w-full py-4 px-5">
      {data?.categories?.map((category: any, categoryIndex: number) => (
        <div className="mb-8" key={categoryIndex}>
          <CustomPublicTableCard
            content={{
              categoryIndex,
              title: category?.name,
              header: ['Category', 'Skills', 'Coefficient', 'Note (From 0 to 5)'],
              rows: category?.skills,
              result: sumCoefficient(category),
              edit: false,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default PreviewPublic;
