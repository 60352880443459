import { useSelector } from 'react-redux';
import { RootState } from 'src/modules/shared/store';
import { fDate } from 'src/utils/format-time';

const ProjectDetails = () => {
  const { project } = useSelector((state: RootState) => state.evaluation);

  return (
    <div className="w-full border rounded-lg py-6 px-4">
      <h2 className="text-[16px] font-semibold">Project Details</h2>
      <div className="mt-8 flex justify-between items-start gap-6 overflow-x-auto pb-3">
        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/1.png" alt="na" />
            Name
          </h5>
          <p className="mt-4 text-sm font-medium text-nowrap text-[#212B36]">{project?.name}</p>
        </div>

        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/2.png" alt="na" />
            Project Owner
          </h5>
          <div className="mt-4 flex items-center gap-2">
            <img src="/assets/icons/kpi/publickpi/7.png" alt="na" />
            {/* <img src="/assets/icons/kpi/publickpi/8.png" alt="na" /> */}
            <p className="text-sm font-medium text-nowrap text-[#212B36]">
              {project?.owner || '-'}
            </p>
          </div>
        </div>

        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/3.png" alt="na" />
            Project Type
          </h5>
          <p className="mt-4 text-sm font-medium text-nowrap text-[#212B36]">{project?.type}</p>
        </div>

        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/4.png" alt="na" />
            Created
          </h5>
          <p className="mt-4 text-sm font-medium text-nowrap text-[#212B36]">
            {fDate(project?.created_at)}
          </p>
        </div>

        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/5.png" alt="na" />
            Start Date
          </h5>
          <p className="mt-4 text-sm font-medium text-nowrap text-[#212B36]">
            {fDate(project?.start_date)}
          </p>
        </div>

        <div className="min-w-[180px]">
          <h5 className="text-sm font-bold text-[#637381] flex items-center gap-2 text-nowrap">
            <img src="/assets/icons/kpi/publickpi/6.png" alt="na" />
            End Date
          </h5>
          <p className="mt-4 text-sm font-medium text-nowrap text-[#212B36]">
            {fDate(project?.due_date)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
