// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HOST_API } from 'src/config-global';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import {
  createProject,
  fetchOneProject,
  fetchProjects,
  removeMemberFromProject,
  switchMembers,
  fetchUserHistory,
  updateProject,
  addMemberToProject,
  filterProjects,
  fetchAllProjects,
  addExtraHoursToMember,
  getExtraHoursOFMember,
  bulkdeleteExtraHours,
  filterSwitchProjects,
  deleteOneProject,
} from './projectThunk';
import { toast } from 'react-toastify';
export interface ProjectType {
  id?: string;
  name: string | null;
  type?: string | null;
  status?: string | null | undefined;
  departments?: string[];
  members?: { id: string; fullName: string }[];
  cover?: string | null | undefined;
  attachments?: (File | string | undefined)[];
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  description?: string | null | undefined;
  estimate?: number | null | undefined;
  estimation_duration?: number | null | undefined;
  extraHours: any;
  comments: any;
  files: any;
  member: any;
  historyProj: any;
  activeProj: any;
  loadingFetch?: any;
  switchProjects?: any;
  // tmp
  dates?: string;
}

export interface ProjectState {
  status: string;
  projects: (ProjectType | null | undefined)[] | null | undefined;
  count: number | null | undefined;
  allCount: number;
  doneCount: number;
  soonCount: number;
  overdueCount: number;
  next: number | null | undefined;
  prev: number | null | undefined;
  project?: ProjectType | null;
  kanbanProjects: any;
  error: string | null;
  isLoading: boolean;
  extraHours: any;
  checkExtraList: any;
  currentProject: any;
  loadingFetch?: any;
  switchProjects?: any;
}

const initialState: ProjectState = {
  status: 'idle',
  projects: [],
  extraHours: [],
  kanbanProjects: [],
  checkExtraList: [],
  currentProject: null,
  switchProjects: null,

  count: 0,
  allCount: 0,
  doneCount: 0,
  soonCount: 0,
  overdueCount: 0,
  next: null,
  prev: null,
  project: null,
  error: null,
  isLoading: false,
  loadingFetch: false,
};

const projectSlice = createSlice({
  name: 'projectState',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.projects = [];
    },
    setCheckListExtraHours: (state, action) => {
      if (action.payload.hours == 'all') {
        state.checkExtraList = state.extraHours.map((item) => item.id);
      } else {
        state.checkExtraList = [...state.checkExtraList, action.payload.hours];
      }
    },
    emptyCheckListExtraHours: (state, action) => {
      state.checkExtraList = [];
    },
    emptyAnHourFromTheList: (state, action) => {
      state.checkExtraList = state.checkExtraList.filter((item) => item != action.payload.hours);
    },
    setProject: (state, action) => {
      const project: any = {
        id: action.payload.id,
        name: action.payload.name,
        type: action.payload.type,
        status: action.payload.status,
        departments: action.payload.department,
        members: action.payload.members.map((member: any) => {
          return { id: member.id, fullName: member.full_name };
        }),
        description: action.payload.description,
        attachments: action.payload.files.map((file: any) => `${HOST_API}${file?.path}`),
        cover: action.payload.cover,
        estimation_duration: action.payload.estimation_duration,
        endDate: action.payload.endDate || action.payload.due_date,
        startDate: action.payload.startDate || action.payload.start_date,
        type: action.payload.type,
        owner: action.payload.owner,
      };
      state.project = project;
    },
    restoreProject: (state) => {
      state.project = initialState.project;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
        state.loadingFetch = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action: PayloadAction<any>) => {
        state.projects = action.payload.projects;
        state.count = action.payload.nbProjects;
        state.allCount = action.payload.nbAll;
        state.doneCount = action.payload.nbDone;
        state.soonCount = action.payload.nbSoon;
        state.overdueCount = action.payload.nbOverdue;
        state.next = action.payload.next;
        state.prev = action.payload.prev;
        state.status = 'succeeded';
        state.isLoading = false;
        state.loadingFetch = false;
      })
      .addCase(filterProjects.fulfilled, (state, action: PayloadAction<any>) => {
        state.projects = action.payload.results;
        state.count = action.payload.nbProjects;
        state.allCount = action.payload.nbAll;
        state.doneCount = action.payload.nbDone;
        state.soonCount = action.payload.nbSoon;
        state.overdueCount = action.payload.nbOverdue;
        state.next = action.payload.next;
        state.prev = action.payload.prev;
        state.status = 'succeeded';
        state.isLoading = false;
        state.loadingFetch = false;
      })
      .addCase(fetchProjects.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(fetchUserHistory.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchUserHistory.fulfilled, (state, action: PayloadAction<any>) => {
        state.projects = action.payload;
        state.member = action.payload.member;
        state.historyProj = action.payload.historyProj;
        state.activeProj = action.payload.activeProj;

        // state.count = action.payload.nbProjects;
        // state.allCount = action.payload.nbAll;
        // state.doneCount = action.payload.nbDone;
        // state.soonCount = action.payload.nbSoon;
        // state.overdueCount = action.payload.nbOverdue;
        state.next = action.payload.next;
        state.prev = action.payload.prev;
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(fetchUserHistory.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder.addCase(fetchOneProject.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchOneProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.project = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchOneProject.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(createProject.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.projects?.unshift(action.payload);
      state.kanbanProjects?.unshift(action.payload);
      state.count = state.count + 1;

      state.status = 'succeeded';
    });
    builder.addCase(createProject.rejected, (state, action: PayloadAction<any>) => {
      if (action?.payload.name) {
        toast.error('Project name already exists');
      }
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder
      .addCase(updateProject.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const { payload } = action;
        const newData = {
          name: payload.name,
          startDate: payload.start_date,
          start_date: payload.start_date,
          endDate: payload.due_date,
          due_date: payload.due_date,
          department: payload.department,
          description: payload.description,
          members: payload.members,
          status: payload.status,
          estimation_duration: payload.estimation_duration,
          files: payload.files,
          type: payload.type,
        };

        state.projects = state.projects?.map((project: ProjectType | null | undefined) =>
          project?.id == payload.id ? { ...current(project), ...newData } : project
        );
        state.kanbanProjects = state.kanbanProjects?.map(
          (project: ProjectType | null | undefined) =>
            project?.id == payload.id ? { ...current(project), ...newData } : project
        );
        state.project = {
          ...payload,
          ...newData,
        };
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(updateProject.rejected, (state, action: PayloadAction<any>) => {
        if (action?.payload.name) {
          toast.error('Project name already exists');
        }
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(switchMembers.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(switchMembers.fulfilled, (state, action) => {
        const { payload } = action;
        state.project = {
          ...payload,
        };
        state.status = 'succeeded';
        state.isLoading = false;
        state.projects = state.projects?.map((project: ProjectType | null | undefined) =>
          project?.id === payload.id ? state.project : project
        );
      })
      .addCase(switchMembers.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(removeMemberFromProject.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(removeMemberFromProject.fulfilled, (state, action) => {
        const { payload } = action;
        state.project = {
          ...payload,
        };
        state.status = 'succeeded';
        state.isLoading = false;
        state.projects = state.projects?.map((project: ProjectType | null | undefined) =>
          project?.id === payload.id ? state.project : project
        );
      })
      .addCase(removeMemberFromProject.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(addMemberToProject.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(addMemberToProject.fulfilled, (state, action) => {
        const { payload } = action;
        state.project = {
          ...payload,
        };
        state.status = 'succeeded';
        state.isLoading = false;
        state.projects = state.projects?.map((project: ProjectType | null | undefined) =>
          project?.id === payload.id ? state.project : project
        );
      })
      .addCase(addMemberToProject.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(fetchAllProjects.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        const { payload } = action;
        state.kanbanProjects = payload.data.results;
      })
      .addCase(fetchAllProjects.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
    builder
      .addCase(addExtraHoursToMember.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(addExtraHoursToMember.fulfilled, (state, action) => {
        const { payload } = action;
        state.extraHours = [...state.extraHours, payload];
      })
      .addCase(addExtraHoursToMember.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
    builder
      .addCase(getExtraHoursOFMember.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getExtraHoursOFMember.fulfilled, (state, action) => {
        const { payload } = action;

        state.extraHours = payload;
      })
      .addCase(getExtraHoursOFMember.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
    builder
      .addCase(bulkdeleteExtraHours.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(bulkdeleteExtraHours.fulfilled, (state, action) => {
        const { payload } = action;
        state.extraHours = current(state.extraHours).filter(
          (item) => !current(state.checkExtraList).includes(item.id)
        );
      })
      .addCase(bulkdeleteExtraHours.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
    builder
      .addCase(filterSwitchProjects.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(filterSwitchProjects.fulfilled, (state, action) => {
        const { payload } = action;
        state.switchProjects = payload.results;
      })
      .addCase(filterSwitchProjects.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
    builder
      .addCase(deleteOneProject.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(deleteOneProject.fulfilled, (state, action) => {
        const { payload } = action;
        state.projects = state.projects?.filter(
          (project: ProjectType | null | undefined) => project?.id != payload.id
        );
      })
      .addCase(deleteOneProject.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
  },
});

export const {
  initialise,
  restoreProject,
  setCheckListExtraHours,
  emptyCheckListExtraHours,
  emptyAnHourFromTheList,
  setProject,
} = projectSlice.actions;

export default projectSlice.reducer;
