import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import {
  createNewSkill,
  deleteSkillById,
  getTemplateById,
  updateCategory,
  updateSkill,
} from '../data/templateThunk';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { sumCoefficient } from '../utils/sumCoefficient';
import { ICategory, ISkills } from '../data/templateType';
import uuidv4 from 'src/utils/uuidv4';
import { toast } from 'react-toastify';

interface CategoryChange {
  categoryId: string;
  oldName: string;
  newName: string;
}

const CustomTableCardFinal = ({ edit, isCancel, isSave }: any) => {
  const dispatch = useAppDispatch();
  const [searchParams, _setSearchParams] = useSearchParams();
  const template_id = searchParams.get('id');

  const { template } = useSelector((state: RootState) => state.templates);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    dispatch(getTemplateById(template_id))
      .unwrap()
      .then((res: any) => {
        setData(res);
      });
  }, [template_id]);

  useEffect(() => {
    setData(template);
  }, [isCancel]);

  useEffect(() => {
    if (isSave) {
      const findChangedCategories = (oldData: any, newData: any) => {
        let hasChange = false;
        oldData.categories.forEach((oldCategory: any) => {
          // get current new category
          const newCategory = newData.categories.find(
            (category: any) => category.id === oldCategory.id
          );

          // check if category updated
          if (oldCategory.name !== newCategory.name) {
            hasChange = true;
            dispatch(updateCategory({ id: newCategory.id, body: { name: newCategory.name } }));
          }

          // check if skill exists
          newCategory?.skills?.forEach((newSkill: any) => {
            const oldSkill = oldCategory?.skills?.find((skill: any) => skill.id === newSkill.id);
            if (!oldSkill) {
              dispatch(
                createNewSkill({
                  criterias_data: [
                    {
                      name: newSkill.name,
                      coefficient: newSkill.coefficient,
                      category: newCategory.id,
                    },
                  ],
                })
              )
                .unwrap()
                .then(() => {
                  hasChange = true;
                  dispatch(getTemplateById(template_id))
                    .unwrap()
                    .then((res: any) => {
                      setData(res);
                    });
                });
            } else {
              if (
                oldSkill.name !== newSkill.name ||
                oldSkill.coefficient !== newSkill.coefficient
              ) {
                hasChange = true;
                dispatch(
                  updateSkill({
                    id: oldSkill.id,
                    category: oldSkill?.category,
                    name: newSkill.name,
                    coefficient: newSkill.coefficient,
                  })
                );
              }
            }
          });
        });
        if (hasChange) {
          toast.success('Updated Successfully');
        }
      };

      findChangedCategories(template, data);
    }
  }, [isSave]);

  const changeCategoryName = (categoryId: string, newName: string): void => {
    setData((prevState: any) => ({
      ...prevState,
      categories: prevState.categories.map((category: any) => {
        if (category.id === categoryId) {
          return {
            ...category,
            name: newName,
          };
        }
        return category;
      }),
    }));
  };

  const handleChange = (
    categoryId: string,
    skillId: string,
    fieldName: string,
    value: string | number
  ) => {
    setData((prevState: any) => {
      const updatedCategories = prevState.categories.map((category: any) => {
        if (category.id === categoryId) {
          return {
            ...category,
            skills: category.skills.map((skill: any) => {
              if (skill.id === skillId) {
                return {
                  ...skill,
                  [fieldName]: value,
                };
              }
              return skill;
            }),
          };
        }
        return category;
      });

      return {
        ...prevState,
        categories: updatedCategories,
      };
    });
  };

  const handleCreate = (categoryId: any) => {
    setData((prevState: any) => {
      const updatedCategories = prevState.categories.map((category: any) => {
        if (category.id === categoryId) {
          const lastSkill = category.skills[category.skills.length - 1];
          if (lastSkill && lastSkill.name !== '' && lastSkill.coefficient !== '') {
            const newSkill = {
              id: uuidv4(),
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              name: '',
              coefficient: '',
              category: categoryId,
            };

            return {
              ...category,
              skills: [...category.skills, newSkill],
            };
          }
        }
        return category;
      });

      return {
        ...prevState,
        categories: updatedCategories,
      };
    });
  };

  const handleDelete = (indexToDelete: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        setData((prevState: any) => ({
          ...prevState,
          categories: prevState.categories.map((category: any) => ({
            ...category,
            skills: category.skills.filter((skill: any) => skill.id !== indexToDelete),
          })),
        }));
        dispatch(deleteSkillById(indexToDelete));
      }
    });
  };

  if (!data) {
    return (
      <div className="mt-8 w-full flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-8">
      {data?.categories?.map((category: ICategory, categoryIndex: number) => (
        <div
          className="overflow-hidden overflow-x-auto rounded-lg block justify-center items-center w-full"
          key={categoryIndex}
        >
          <div className="w-full block rounded-t-lg">
            {/* body */}
            <div className="flex">
              <div className="w-full border-2 flex flex-col">
                <p
                  className={`p-4 bg-[#F4F6F8] text-[#637381] w-full font-semibold text-sm border-b-2`}
                >
                  Category
                </p>
                <div className="flex justify-center items-center py-3 px-4 text-[#637381] w-full font-semibold text-base text-nowrap h-full">
                  {edit ? (
                    <input
                      tabIndex={
                        categoryIndex *
                          (categoryIndex === 0
                            ? 0
                            : data?.categories?.[categoryIndex - 1]?.skills?.length! * 2) +
                        1 +
                        categoryIndex
                      }
                      type="text"
                      required
                      className={`focus:border-2 focus:border-indigo-200 w-full block text-[#637381] text-nowrap text-center font-semibold text-base`}
                      value={category?.name}
                      onChange={(e) => changeCategoryName(category?.id, e.target.value)}
                    />
                  ) : (
                    category?.name
                  )}
                </div>
              </div>

              <div className="text-[#637381] font-medium text-sm border-r-2 border-t-2 border-b-2 w-full">
                <p className={`p-4 bg-[#F4F6F8] text-[#637381] font-semibold text-sm border-b-2`}>
                  Skills
                </p>

                {category?.skills?.map((skill: ISkills, index: number) =>
                  edit ? (
                    <input
                      tabIndex={
                        categoryIndex *
                          (categoryIndex === 0
                            ? 0
                            : data?.categories?.[categoryIndex - 1]?.skills?.length! * 2) +
                        1 +
                        categoryIndex +
                        (2 * index + 1)
                      }
                      key={index}
                      type="text"
                      required
                      className={`focus:border-2 focus:border-indigo-200 w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                        index === category?.skills?.length! - 1 ? '' : 'border-b-2'
                      }`}
                      value={skill?.name}
                      onChange={(e) =>
                        handleChange(category?.id, skill?.id, 'name', e.target.value)
                      }
                    />
                  ) : (
                    <p
                      className={`w-full text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                        index === category?.skills?.length! - 1 ? '' : 'border-b-2'
                      }`}
                      key={index}
                    >
                      {skill?.name}
                    </p>
                  )
                )}
              </div>

              <div className="w-full flex relative">
                {/* <div
                  className="absolute top-2 right-2 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabIndex={-1}
                >
                  <div className="py-4 px-6" role="none">
                    Delete entire Category
                  </div>
                </div> */}

                <div className="text-[#637381] font-medium text-sm border-t-2 border-r-2 border-b-2 w-full">
                  <div className="flex justify-between border-b-2 bg-[#F4F6F8]">
                    <p className={`w-full p-4 text-[#637381] font-semibold text-sm`}>Coefficient</p>

                    {edit && (
                      <div className="flex justify-center items-center px-2 border-l-2">
                        <img
                          src="/assets/icons/kpi/action.png"
                          alt="action"
                          className="w-[28px] h-[28px] object-none"
                        />
                      </div>
                    )}
                  </div>

                  {category?.skills?.map((skill: any, index: number) => (
                    <div
                      className={`flex justify-between ${
                        index === category.skills?.length! - 1 ? '' : 'border-b-2'
                      }`}
                      key={index}
                    >
                      {edit ? (
                        <input
                          tabIndex={
                            categoryIndex *
                              (categoryIndex === 0
                                ? 0
                                : data?.categories?.[categoryIndex - 1]?.skills?.length! * 2) +
                            1 +
                            categoryIndex +
                            (2 * index + 2)
                          }
                          type="number"
                          required
                          className={`w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap`}
                          value={skill.coefficient}
                          onChange={(e) =>
                            handleChange(category?.id, skill?.id, 'coefficient', e.target.value)
                          }
                        />
                      ) : (
                        <p className={`w-full text-[#212B36] text-xs font-medium p-4 text-nowrap`}>
                          {skill.coefficient}
                        </p>
                      )}

                      {edit && (
                        <div className="flex justify-center items-center px-2 border-l-2">
                          <img
                            src="/assets/icons/kpi/delete.png"
                            alt="action"
                            className="w-[28px] h-[28px] cursor-pointer"
                            onClick={() => handleDelete(skill.id)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Add one */}
          {edit && (
            <div className="flex justify-end w-full">
              <div
                className={`border border-t-0 rounded-b-lg overflow-hidden w-full flex justify-start items-center px-4 gap-3`}
              >
                <img
                  src="/assets/icons/kpi/new.png"
                  alt=""
                  className="cursor-pointer"
                  onClick={() => handleCreate(category?.id)}
                />
                <div
                  className="py-3 text-[#637381] font-bold text-sm cursor-pointer"
                  onClick={() => handleCreate(category?.id)}
                >
                  Add New Skill
                </div>
              </div>
            </div>
          )}

          {/* footer */}
          <div className="w-full flex justify-end">
            <div className={`w-full`}></div>
            <div className={`w-full`}></div>
            <div className={`border border-t-0 rounded-b-lg overflow-hidden w-full bg-[#FBFBFB]`}>
              <div className="py-3 px-4 text-[#637381] font-bold text-sm">
                {sumCoefficient(category)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomTableCardFinal;
