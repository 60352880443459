import { TextField } from '@mui/material';

const CreateDepartment = ({ errors, setErrors, data, setData }: any) => {
  const handleChangeDepartmentName = (e: any) => {
    setErrors((prev: any) => ({
      ...prev,
      departmentName: undefined,
    }));
    setData((prevData: any) => ({
      ...prevData,
      name: e.target.value,
    }));
  };

  const handleChangeDepartmentNote = (e: any) => {
    setErrors((prev: any) => ({
      ...prev,
      departmentNote: undefined,
    }));
    setData((prevData: any) => ({
      ...prevData,
      max_score: e.target.value,
    }));
  };

  return (
    <div className="p-10 border rounded-lg flex gap-8">
      <TextField
        label="Department Name"
        variant="outlined"
        sx={{ width: '420px' }}
        onChange={handleChangeDepartmentName}
        error={errors?.departmentName}
        helperText={errors?.departmentName}
        defaultValue={data?.name}
      />

      {/* <TextField
        type="number"
        label="Note"
        variant="outlined"
        sx={{ width: '120px' }}
        onChange={handleChangeDepartmentNote}
        error={errors?.departmentNote}
        helperText={errors?.departmentNote}
        defaultValue={data?.max_score}
      /> */}
    </div>
  );
};

export default CreateDepartment;
