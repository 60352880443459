import { combineReducers } from '@reduxjs/toolkit';

import KanbanBoardReducer from 'src/api/overview/slices';
import fileReducer from '../../../api/files/data/fileSlice';
import authReducer from '../../auth/data/authSlice';
import projectReducer from '../../project/data/projectSilce';
import userReducer from '../../user/data/userSilce';
import departmentsReducer from '../components/departments/data/departmentsSilce';
import typeReducer from '../components/project-type/data/typeSilce';
import rolesReducer from '../components/role/data/rolesSilce';
import statusReducer from '../components/status/data/statusSilce';
import CommentReducer from '../../../api/comment/data/commentSlice';
import templatesReducer from '../../kpi/data/templateSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import evaluationReducer from '../../kpi/data/evaluationSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, projectReducer);

const rootReducer = combineReducers({
  auth: authReducer,
  projectState: persistedReducer,
  user: userReducer,
  kanban: KanbanBoardReducer,
  departments: departmentsReducer,
  roles: rolesReducer,
  status: statusReducer,
  types: typeReducer,
  files: fileReducer,
  comment: CommentReducer,
  templates: templatesReducer,
  evaluation: evaluationReducer,
});

export default rootReducer;
