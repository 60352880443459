/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axios';
import { departmentPayload } from './departmentsTypes';

export const fetchDepartments = createAsyncThunk(
  'departments/fetchDepartments',
  async (query: { page?: number; page_size?: number }) => {
    const { page = 1, page_size = 7 } = query;
    const res = await axiosInstance.get(`/departments/`, { params: { page, page_size } });

    const departments = res.data.results;
    const nbDepartments = res.data.count;
    const next = res.data.next;
    const prev = res.data.prev;

    return { departments, nbDepartments, next, prev };
  }
);

export const deleteDepartments = createAsyncThunk(
  'departments/deleteDepartments',
  async (ids, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.delete(`/departments/`, {
        data: { ids },
      });
      data = await response.data;
      if (response.status === 200) {
        return { data, status: 'success' };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message,
      });
    }
  }
);
export const fetchOneDepartment = createAsyncThunk(
  'departments/fetchOneDepartment',
  async (id: string, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.get(`/departments/${id}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createDepartment = createAsyncThunk(
  'departments/createUser',
  async (payload: departmentPayload, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/departments/`, payload);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
