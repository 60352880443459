/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axios';
import { TypePayload } from './typeTypes';

export const fetchTypes = createAsyncThunk(
  'type/fetchTypes',
  async ({ page = 1, page_size = 7 }: { page?: number; page_size?: number }) => {
    const res = await axiosInstance.get(`/project-type/`, { params: { page, page_size } });

    const types = res.data.results;
    const nbTypes = res.data.count;
    const next = res.data.next;
    const prev = res.data.prev;

    return { types, nbTypes, next, prev };
  }
);

export const deleteRoles = createAsyncThunk('type/deleteTypes', async (ids, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.delete(`/project-type/`, {
      data: { ids },
    });
    data = await response.data;
    if (response.status === 200) {
      return { data, status: 'success' };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message,
    });
  }
});
export const fetchOneType = createAsyncThunk('type/fetchOneType', async (id: string, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/project-type/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createType = createAsyncThunk(
  'type/createType',
  async (payload: TypePayload, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/project-type/`, payload);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
