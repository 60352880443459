import { useEffect, useState } from 'react';
import PreviewPublic from './PreviewPublic';
import PublicTotal from './PublicTotal';
import PublicFooter from './PublicFooter';
import PublicTeam from './PublicTeam';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import { filterProjectMembers, setEvaluationData } from '../../data/evaluationSlice';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { getUserEvaluationById } from '../../data/evaluationThunk';

const EvaluationCard = () => {
  const dispatch = useAppDispatch();
  const { po_id, project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get('user_id');
  const token = searchParams.get('token');

  const { project, template } = useSelector((state: RootState) => state.evaluation);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState();

  const handleChange = (index: any, type: any) => {
    setSelectedTab(index);
    dispatch(filterProjectMembers(type));
  };

  useEffect(() => {
    const first_member_id = project?.members?.filter((member: any) => member?.id !== po_id)?.[0]
      ?.id;
    setSelectedItem(first_member_id);
    setSearchParams({ ...Object.fromEntries(searchParams), user_id: String(first_member_id) });

    if (first_member_id) {
      dispatch(
        getUserEvaluationById({
          token,
          user_id: first_member_id,
          project_id,
        })
      );
    }
  }, []);

  return (
    <div className="w-full rounded-lg bg-[#FFFFFF] border">
      <div className="w-full bg-[#F4F6F8] h-14 flex items-center gap-6 px-6 border-b-2">
        <p
          className={`cursor-pointer pb-1 ${
            selectedTab === 0 ? 'border-b-2 border-[#592D8C]' : ''
          }`}
          onClick={() => handleChange(0, 'all')}
        >
          All
        </p>
        {project?.department?.map((item: any, index: number) => (
          <p
            className={`cursor-pointer pb-1 ${
              selectedTab === index + 1 ? 'border-b-2 border-[#592D8C]' : ''
            }`}
            onClick={() => handleChange(index + 1, item?.name)}
          >
            {item?.name}
          </p>
        ))}
      </div>

      <div className="w-full flex">
        <div className="border-r-2 py-4 px-3 bg-[#F4F6F8]">
          <PublicTeam selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </div>
        <div className="w-full">
          <PreviewPublic data={template} />
          <PublicTotal />
          <PublicFooter selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </div>
      </div>
    </div>
  );
};

export default EvaluationCard;
