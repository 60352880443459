const NoPermission = () => {
  return (
    <div className="bg-slate-50 w-full h-screen flex flex-col gap-4 justify-center items-center">
      <h1 className="text-4xl font-bold">Permission Denied!</h1>
      <p className="text-xl font-normal">You do not have permission to access this page</p>
      <img
        src={'/assets/icons/kpi/no-permission.svg'}
        className="max-w-[450px] max-h-[450px]"
        alt="no"
      />
    </div>
  );
};

export default NoPermission;
