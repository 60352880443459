/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createFile, fetchFiles } from './fileThunk';

export interface FilesState {
  status: string;
  files: [] | null;
  error: string | null;
  file: {
    content: any | null;
    project: any | null;
    user: any | null;
  } | null;
  isLoading: boolean;
}

const initialState: FilesState = {
  status: 'idle',
  files: [],
  file: null,
  error: null,
  isLoading: false,
};

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.files = [];
    },
    restoreFile: (state) => {
      state.file = initialState.file;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFile.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(createFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.file = {
          ...payload,
        };
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(createFile.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });

    builder
      .addCase(fetchFiles.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
        const { payload } = action;
        state.file = {
          ...payload,
        };
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(fetchFiles.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
  },
});

export const { initialise, restoreFile } = fileSlice.actions;

export default fileSlice.reducer;
