import { Breadcrumbs, Container, Modal, Stack, Typography } from '@mui/material';
import { useSettingsContext } from 'src/modules/shared/components/settings';
import CustomTable from '../components/CustomTable';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import { getTemplateById } from '../data/templateThunk';
import { useSelector } from 'react-redux';
import CustomTableCard from '../components/CustomTableCard';
import { sumCoefficient } from '../utils/sumCoefficient';
import CustomTableCardComplete from '../components/CustomTableCardComplete';
import CustomTableCardCompleteNew from '../components/CustomTableCardCompleteNew';
import { ITemplate } from '../data/templateType';
import CustomTableCardFinal from '../components/CustomTableCardFinal';
import CreateCategoryCard from '../components/CreateCategoryCard';

const KpiDetails = () => {
  const settings = useSettingsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { template } = useSelector((state: RootState) => state.templates);
  const template_id = searchParams.get('id');
  const dispatch = useAppDispatch();

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      Evaluation
    </Typography>,
    <Typography key="2" color="text.primary">
      KPI
    </Typography>,
    <Typography key="2" color="text.primary">
      Team Lead
    </Typography>,
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isSave, setIsSave] = useState(false);

  const handleCancelChange = () => {
    setIsEdit(false);
    setIsCancel(true);
  };

  const handleEdit = () => {
    setIsEdit(true);
    setIsSave(false);
    setIsCancel(false);
  };

  const handleSave = () => {
    setIsEdit(false);
    setIsSave(true);
  };

  const [open, setOpen] = useState(false);

  const handleCreateCategory = () => {
    setOpen(true);
  };

  return (
    <div>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '60px',
          backgroundColor: 'rgba(255, 255, 255, 0.798)',
          paddingTop: '20px !important',
          paddingBottom: '60px !important',

          position: 'relative',
          width: '103%',
          padding: '0px 50px',
          left: '-16px',
          top: '-8px',
          borderTop: '1px solid #e0e0e0',
          marginBottom: '40px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <div
          style={{
            backgroundColor: '#FFF',
            boxShadow:
              '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
            height: '100%',
            padding: '40px 50px',
            overflowY: 'auto',
          }}
        >
          <div className="flex justify-between items-center">
            <div>
              <Typography
                variant="h4"
                style={{
                  color: '#212B36',
                  fontFamily: 'Public Sans',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                }}
              >
                {template?.name}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  color: '#637381',
                  fontFamily: 'Public Sans',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                }}
              >
                Add New Skills or Create new Category
              </Typography>
            </div>

            <div className="flex justify-center items-center gap-6">
              <button
                onClick={handleCreateCategory}
                className="flex justify-center items-center gap-2 text-[#592D8C] rounded-lg font-bold border px-6 py-3 border-[#592D8C]"
              >
                <img src="/assets/icons/kpi/add.png" alt="add" />
                Create New Category
              </button>

              {!isEdit ? (
                <button
                  className="flex justify-center items-center gap-2 text-[#592D8C] rounded-lg font-bold border px-6 py-3"
                  onClick={handleEdit}
                >
                  <img src="/assets/icons/kpi/edit.png" alt="add" />
                  Edit
                </button>
              ) : (
                <>
                  <button
                    className="flex justify-center items-center gap-2 text-[#333] rounded-lg font-bold border px-6 py-3"
                    onClick={handleCancelChange}
                  >
                    <img src="/assets/icons/kpi/cancel.png" alt="add" />
                    Cancel Changes
                  </button>

                  <button
                    className="flex justify-center items-center gap-2 text-[#fff] bg-[#592D8C] rounded-lg font-bold border px-6 py-3"
                    onClick={handleSave}
                  >
                    <img src="/assets/icons/kpi/save.png" alt="add" />
                    Save
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="mt-8 flex justi fy-start items-center flex-wrap gap-9">
            {/* {template?.categories?.map((item: any, index: number) => (
              <CustomTableCardComplete
                key={index}
                header={['Category', 'Skills', 'Coefficient']}
                content={item}
                result={sumCoefficient(item)}
                edit={isEdit}
              />
            ))} */}

            <CustomTableCardFinal edit={isEdit} isCancel={isCancel} isSave={isSave} />

            {/* {template?.categories?.map((item: any, index: number) => (
              <CustomTableCard
                key={index}
                content={{
                  title: item?.name,
                  header: ['Category', 'Skills', 'Coefficient'],
                  rows: item?.skills,
                  setRows: null,
                  result: sumCoefficient(item),
                  edit: isEdit,
                }}
              />
            ))} */}
          </div>
        </div>
      </Container>

      <Modal
        onClose={() => setOpen(false)}
        open={open}
        style={{
          width: '50%',
          height: '50%',
          margin: 'auto',
        }}
      >
        <div className="bg-white rounded-xl">
          <CreateCategoryCard setOpen={setOpen} />
        </div>
      </Modal>
    </div>
  );
};

export default KpiDetails;
