import React, { useEffect } from 'react';
import axiosInstance from 'src/modules/shared/utils/axios';
import { useSelector } from 'react-redux';
import { clearTokens, getTokens } from '../utils/token';
import { initialise } from '../data/authSlice';
import { RootState, store } from 'src/modules/shared/store';
import { SplashScreen } from 'src/components/loading-screen';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface JwtPayload {
  exp: number;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const isValidToken = (token: string) => {
  const decoded: JwtPayload = parseJwt(token || '');
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const fetchUser = async function fetchUser() {
  const { refresh_token } = getTokens();
  if (refresh_token && isValidToken(refresh_token)) {
    try {
      const response = await axiosInstance.get('/user/me');

      const user = response.data.data;

      store.dispatch(initialise({ isAuthenticated: true, user }));
    } catch (err) {
      store.dispatch(initialise({ isAuthenticated: false, user: null }));
      clearTokens();
    }
  } else {
    store.dispatch(initialise({ isAuthenticated: false, user: null }));
    clearTokens();
  }
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { isInitialised } = useSelector((state: RootState) => state.auth);

  const currentWorkspace = localStorage.getItem('currentWorkspace');

  useEffect(() => {
    if (!isMounted.current) return;
    fetchUser();
  }, [currentWorkspace]);

  if (!isInitialised) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default AuthProvider;
