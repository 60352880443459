import { TextField } from '@mui/material';
import { toast } from 'react-toastify';

const CreateSkillCategory = ({ index, data, setData }: any) => {
  const handleChangeSkillName = (e: any, index: number, skillIndex: number) => {
    setData((prevData: any) => {
      const newData = { ...prevData };
      newData.skills[skillIndex].name = e.target.value;
      return newData;
    });
  };

  const handleChangeSkillCoefficient = (e: any, index: number, skillIndex: number) => {
    setData((prevData: any) => {
      const newData = { ...prevData };
      newData.skills[skillIndex].coefficient = e.target.value;
      return newData;
    });
  };

  const handleCreate = (index: number) => {
    const category = data;

    if (category?.skills?.some((item: any) => item?.name === '' || item?.coefficient === '')) {
      toast.warn('Please fill in all skills');
      return;
    }

    const newSkill = {
      name: '',
      coefficient: '',
    };

    setData((prevData: any) => ({
      ...prevData,
      skills: [...prevData.skills, newSkill],
    }));
  };

  const handleDelete = (index: number, skillIndex: number) => {
    setData((prevData: any) => {
      const newData = { ...prevData };
      if (newData.skills?.length === 1) {
        return prevData;
      }
      newData.skills.splice(skillIndex, 1);
      return newData;
    });
  };

  return (
    <div className="flex flex-col gap-3">
      {data?.skills?.map((item: any, skillIndex: number) => (
        <div className="flex justify-center items-center gap-3">
          <TextField
            label="Skill"
            variant="outlined"
            size="small"
            sx={{ width: '75%', backgroundColor: '#fff', borderRadius: '9px' }}
            onChange={(e) => handleChangeSkillName(e, index, skillIndex)}
            value={item?.name}
          />
          <TextField
            label="Coefficient"
            type="number"
            variant="outlined"
            size="small"
            sx={{ width: '25%', backgroundColor: '#fff', borderRadius: '9px' }}
            onChange={(e) => handleChangeSkillCoefficient(e, index, skillIndex)}
            value={item?.coefficient}
          />
          <img
            src="/assets/icons/kpi/delete-btn.png"
            alt="remove"
            className={`cursor-pointer w-[35px] h-[35px] ${
              data?.skills?.length === 1 ? 'cursor-not-allowed' : ''
            }`}
            onClick={() => handleDelete(index, skillIndex)}
          />
        </div>
      ))}
      <div>
        <button
          className="mx-2 flex justify-center items-center gap-1 border border-[#AF9AC8] rounded-lg text-xs py-2 px-3"
          onClick={() => handleCreate(index)}
        >
          <img src="/assets/icons/kpi/add.png" alt="add" className={`w-[18px] h-[18px]`} />
          Add Skill
        </button>
      </div>
    </div>
  );
};

export default CreateSkillCategory;
