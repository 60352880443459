import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { clearTokens, getTokens, setTokens } from './token';

// ----------------------------------------------------------------------

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  // 'Cache-Control': 'no-cache',
};

export const publicInstance = axios.create({ baseURL: HOST_API, headers });
const axiosInstance = axios.create({ baseURL: HOST_API, headers });

axiosInstance.interceptors.request.use(
  (config) => {
    const { access_token } = getTokens();
    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const previousRequest = error?.config;
    if (error?.response?.status === 401 && !previousRequest?.sent) {
      previousRequest.sent = true;
      try {
        const { refresh_token } = getTokens();
        const response = await axios.post(HOST_API + '/user/token/refresh', {
          refresh: refresh_token,
        });
        const { access, refresh } = response.data.data;
        setTokens(access, refresh);
        previousRequest.headers['Authorization'] = `Bearer ${access}`;
        return axiosInstance(previousRequest);
      } catch (err) {
        clearTokens();
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong!');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: 'api/kanban',

  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
