/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOneRole, fetchRoles } from './rolesThunk';

export interface RolesState {
  status: string;
  roles: [] | null | any;
  nbRoles: number | null;
  next: number | null;
  prev: number | null;
  error: string | null;
  role: {
    id: string | null;
    name: string | null;
  } | null;
}

const initialState: RolesState = {
  status: 'idle',
  roles: [],
  nbRoles: null,
  next: null,
  prev: null,
  role: null,
  error: null,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.roles = [];
    },
    restoreRole: (state) => {
      state.role = initialState.role;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoles.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchRoles.fulfilled, (state, action: PayloadAction<any>) => {
      state.roles = action.payload.roles;
      state.status = 'succeeded';
    });
    builder.addCase(fetchRoles.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(fetchOneRole.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchOneRole.fulfilled, (state, action: PayloadAction<any>) => {
      state.role = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchOneRole.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
  },
});

export const { initialise, restoreRole } = roleSlice.actions;

export default roleSlice.reducer;
