import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { profileRoutes } from 'src/modules/profile/routes/profile';
import { ProjectRoutes } from 'src/modules/project/routes/project';
import { userRoutes } from 'src/modules/user/routes/user';
import { authRoutes } from '../../auth/routes/auth';
import { dashboardRoutes } from '../../dashboard/routes/dashboard';
import { kpiRoutes } from '../../kpi/routes/index';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    ...ProjectRoutes,

    ...userRoutes,

    ...profileRoutes,

    ...kpiRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
