/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'src/modules/shared/utils/axios';
import { ITemplate } from './templateType';

export const createTemplate = createAsyncThunk(
  'templates/bulk-create',
  async (query: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/templates/bulk-create/`, query);

      if (response.status === 201) {
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const generateToken = createAsyncThunk('projects/generate-token', async () => {
  const res = await axiosInstance.get(`/user_criteria/generate-token`);
  return res;
});

export const getTemplates = createAsyncThunk('templates/', async (page: any) => {
  try {
    const response = await axiosInstance.get(`/templates/?page=${page || 1}`);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const getTemplateById = createAsyncThunk('templates/id/', async (id: any) => {
  try {
    const templateResponse = await axiosInstance.get(`/templates/${id}/`);
    const categoriesResponse = await axiosInstance.get(`/categories/${id}/template`);

    const template: ITemplate = {
      id: templateResponse.data.id,
      name: templateResponse.data.name,
      note: String(templateResponse.data.max_score),
      categories: categoriesResponse.data,
    };

    if (templateResponse.status === 200 && categoriesResponse.status === 200) {
      return template;
    }

    throw new Error(templateResponse.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const deleteCategoryById = createAsyncThunk('deleteCategoryById', async (id: any) => {
  try {
    const response = await axiosInstance.delete(`/categories/${id}`);

    if (response.status === 204) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const deleteSkillById = createAsyncThunk('deleteSkillById', async (id: any) => {
  try {
    const response = await axiosInstance.delete(`/criterias/${id}`);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const createCategory = createAsyncThunk('createCategory', async (query: any) => {
  try {
    const response = await axiosInstance.post(`/categories/`, query);

    if (response.status === 201) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const updateCategory = createAsyncThunk('updateCategory', async (query: any) => {
  try {
    const response = await axiosInstance.patch(`/categories/${query.id}`, query.body);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const createNewSkill = createAsyncThunk('createNewSkill', async (query: any) => {
  try {
    const response = await axiosInstance.post(`/criterias/`, query);

    if (response.status === 201) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const updateSkill = createAsyncThunk('updateSkill', async (query: any) => {
  try {
    const response = await axiosInstance.patch(`/criterias/${query.id}`, query);

    if (response.status === 200) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});

export const getSkillsByCategoryId = createAsyncThunk(
  'getSkillsByCategoryId',
  async (query: any) => {
    try {
      const response = await axiosInstance.get(`/criterias/${query}/category`);

      if (response.status === 200) {
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err: any) {
      return Promise.reject(err.message);
    }
  }
);

export const deleteTemplateById = createAsyncThunk('deleteTemplateById', async (id: any) => {
  try {
    const response = await axiosInstance.delete(`/templates/${id}`);

    if (response.status === 204) {
      return response.data;
    }

    throw new Error(response.statusText);
  } catch (err: any) {
    return Promise.reject(err.message);
  }
});
