import { useSelector } from 'react-redux';
import { sumNotes } from '../../utils/sumCoefficient';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import { setEvaluationChanged, setEvaluationData } from '../../data/evaluationSlice';

interface ContentProps {
  categoryIndex: number;
  header: string[];
  title: string;
  rows: string[][];
  result: string;
  edit: boolean;
}

interface CustomTableProps {
  content: ContentProps;
}

const CustomPublicTableCard = ({ content }: CustomTableProps) => {
  const dispatch = useAppDispatch();
  const { evaluationData, evaluationChanged } = useSelector((state: RootState) => state.evaluation);

  const handleChange = (categoryIndex: any, skillIndex: any, e: any) => {
    if (Number(e.target.value) <= 5) {
      let updatedResult = JSON.parse(JSON.stringify(evaluationData));
      updatedResult[categoryIndex].results[skillIndex].score = parseFloat(e.target.value);

      dispatch(setEvaluationData(updatedResult));
      if (!evaluationChanged) {
        dispatch(setEvaluationChanged(true));
      }
    }
  };

  const handleDelete = (indexToDelete: any) => {};

  const handleCreate = () => {};

  return (
    <div className="overflow-hidden overflow-x-auto rounded-lg block justify-center items-center w-full">
      <div className="block rounded-t-lg">
        {/* body */}
        <div className="flex">
          <div className="w-full border-2 flex flex-col">
            <p
              className={`p-4 bg-[#F4F6F8] text-[#637381] w-full font-semibold text-sm border-b-2`}
            >
              {content?.header?.[0]}
            </p>
            <div className="flex justify-center items-center p-4 text-[#637381] w-full font-semibold text-base text-nowrap h-full">
              {content?.title}
            </div>
          </div>

          <div className="text-[#637381] font-medium text-sm border-r-2 border-t-2 border-b-2 w-full">
            <p className={`p-4 bg-[#F4F6F8] text-[#637381] font-semibold text-sm border-b-2`}>
              {content?.header?.[1]}
            </p>

            {content?.rows?.map((row: any, index: number) =>
              content?.edit ? (
                <input
                  type="text"
                  required
                  className={`w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  value={content?.rows?.[index]}
                  onChange={(e) => handleChange(0, index, e)}
                />
              ) : (
                <p
                  className={`text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  key={index}
                >
                  {row?.name}
                </p>
              )
            )}
          </div>

          <div className="w-full flex">
            <div className="text-[#637381] font-medium text-sm border-t-2 border-r-2 border-b-2 w-full">
              <div className="flex justify-between border-b-2 bg-[#F4F6F8]">
                <p className={`p-4 text-[#637381] font-semibold text-sm`}>{content?.header?.[2]}</p>

                {content?.edit && (
                  <div className="flex justify-center items-center px-2 border-l-2">
                    <img
                      src="/assets/icons/kpi/action.png"
                      alt="action"
                      className="w-[28px] h-[28px] object-none"
                    />
                  </div>
                )}
              </div>

              {content?.rows?.map((row: any, index: number) => (
                <div
                  className={`flex justify-between ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  key={index}
                >
                  {content?.edit ? (
                    <input
                      type="text"
                      required
                      className={`w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap`}
                      value={content?.rows?.[index]}
                      onChange={(e) => handleChange(1, index, e)}
                    />
                  ) : (
                    <p className={`text-[#212B36] text-xs font-medium p-4 text-nowrap`}>
                      {row?.coefficient}
                    </p>
                  )}

                  {content?.edit && (
                    <div className="flex justify-center items-center px-2 border-l-2">
                      <img
                        src="/assets/icons/kpi/delete.png"
                        alt="action"
                        className="w-[28px] h-[28px] cursor-pointer"
                        onClick={() => handleDelete(index)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="text-[#637381] font-medium text-sm border-r-2 border-t-2 border-b-2 w-full">
            <p className={`p-4 bg-[#F4F6F8] text-[#637381] font-semibold text-sm border-b-2`}>
              {content?.header?.[3]}
            </p>

            {content?.rows?.map((row: any, index: number) => (
              <input
                type="number"
                required
                className={`focus:border-2 focus:border-indigo-200 w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                  index === content?.rows?.length - 1 ? '' : 'border-b-2'
                }`}
                value={evaluationData?.[content?.categoryIndex]?.results?.[index]?.score}
                onChange={(e) => handleChange(content?.categoryIndex, index, e)}
                min={0}
                max={5}
                maxLength={1}
                onKeyDown={(e) => {
                  if (Number(e.key) > 5 || e.key === 'e' || e.key === 'E') {
                    e.preventDefault();
                  }
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Add one */}
      {content?.edit && (
        <div className="flex justify-end w-full">
          <div
            className={`border border-t-0 rounded-b-lg overflow-hidden w-full flex justify-start items-center px-4 gap-3`}
          >
            <img
              src="/assets/icons/kpi/new.png"
              alt=""
              className="cursor-pointer"
              onClick={handleCreate}
            />
            <div
              className="py-3 text-[#637381] font-bold text-sm cursor-pointer"
              onClick={handleCreate}
            >
              Add New Skill
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex justify-end w-full">
        <div className={`w-full`}></div>
        <div className={`w-full`}></div>
        <div className={`border border-t-0 rounded-bl-lg overflow-hidden w-full bg-[#FBFBFB]`}>
          <div className="py-3 px-4 text-[#637381] font-bold text-sm">{content?.result}</div>
        </div>
        <div className={`border border-t-0 rounded-br-lg overflow-hidden w-full bg-[#FBFBFB]`}>
          <div className="py-3 px-4 text-[#637381] font-bold text-sm">
            {sumNotes(evaluationData?.[content?.categoryIndex])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPublicTableCard;
