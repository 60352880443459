import {
  Box,
  Breadcrumbs,
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSettingsContext } from 'src/components/settings';
import CreateDepartment from '../components/CreateDepartment';
import CreateCategory from '../components/CreateCategory';
import Preview from '../components/Preview';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/modules/shared/store';
import { useNavigate } from 'react-router';
import { createTemplate } from '../data/templateThunk';

type CategoryProps = {
  name: string;
  skills?: { name: string; coefficient: string }[];
};

type TemplateProps = {
  name: string;
  // max_score: string;
  categories?: CategoryProps[];
};

const Template = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const settings = useSettingsContext();

  const breadcrumbs = [
    <Typography key="1" color="text.primary">
      Evaluation
    </Typography>,
    <Typography key="2" color="text.primary">
      KPI
    </Typography>,
  ];

  const [data, setData] = useState<TemplateProps>({
    name: '',
    // max_score: '',
    categories: [
      {
        name: '',
        skills: [
          {
            name: '',
            coefficient: '',
          },
        ],
      },
    ],
  });
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState(null);

  const handleNext = (e: any) => {
    if (activeStep === 0) {
      if (data.name.trim() === '') {
        // if (data.name.trim() === '' || data.max_score.trim() === '') {
        setErrors((prev: any) => ({
          ...prev,
          departmentName: data.name.trim() === '' ? 'name is required!' : undefined,
          // departmentNote: data.max_score.trim() === '' ? 'note is required!' : undefined,
        }));
        return;
      }
    }

    if (activeStep === 1) {
      if (data?.categories?.find((category: any) => category?.name === '')) {
        toast.warn('Please fill in all categories');
        return;
      }

      const skillWithNull = data?.categories
        ?.flatMap((category) => category?.skills)
        ?.find((skill: any) => !skill.name || !skill.coefficient);

      if (skillWithNull) {
        toast.warn('Please fill in all Skills');
        return;
      }
    }

    setActiveStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleSubmit = () => {
    dispatch(createTemplate({ template: [data] }))
      .unwrap()
      .then(() => {
        toast.success('Successfully created');
        navigate('/kpi');
      })
      .catch((err) => {
        toast.error(err.error || 'Failed!');
      });
  };

  return (
    <div>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '60px',
          backgroundColor: 'rgba(255, 255, 255, 0.798)',
          paddingTop: '20px !important',
          paddingBottom: '60px !important',
          position: 'relative',
          width: '103%',
          padding: '0px 50px',
          left: '-16px',
          top: '-8px',
          borderTop: '1px solid #e0e0e0',
          marginBottom: '40px',
        }}
      >
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <div
          style={{
            backgroundColor: '#FFF',
            boxShadow:
              '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
            height: '100%',
            padding: '40px 50px',
            overflowY: 'auto',
            borderRadius: '9px',
          }}
        >
          <div className="flex justify-between items-center">
            <div>
              <Typography
                variant="h4"
                style={{
                  color: '#212B36',
                  fontFamily: 'Public Sans',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                }}
              >
                Customize your KPIs per department
              </Typography>
            </div>
          </div>
          <div className="mt-8 min-h-[55vh] border border-[#dadde03d] rounded-xl overflow-hidden flex flex-col justify-between">
            <div>
              <div className="py-6 bg-[#919EAB14]">
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel>Department Details</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Add Categories</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>Preview</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
              </div>

              <div className="my-12 flex justify-center">
                {activeStep === 0 && (
                  <CreateDepartment
                    errors={errors}
                    setErrors={setErrors}
                    data={data}
                    setData={setData}
                  />
                )}
                {activeStep === 1 && <CreateCategory data={data} setData={setData} />}
                {activeStep === 2 && <Preview data={data} />}
              </div>
            </div>

            <div className="px-8 py-4 border-t border-[#dadde03d] flex justify-end gap-4">
              {activeStep !== 0 && (
                <button className="rounded-lg px-4 py-2 border" onClick={handlePrev}>
                  Previous
                </button>
              )}

              {activeStep !== 2 && (
                <button
                  className="bg-[#592D8C] rounded-lg px-4 py-2 text-white flex justify-center items-center gap-2"
                  onClick={handleNext}
                >
                  Next <img src="/assets/icons/kpi/next.png" alt="next" />
                </button>
              )}

              {activeStep === 2 && (
                <button
                  className="rounded-lg px-4 py-2 border bg-[#592D8C] text-white flex justify-center items-center gap-2"
                  onClick={handleSubmit}
                >
                  <img src="/assets/icons/kpi/check.png" alt="next" />
                  Finish
                </button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Template;
