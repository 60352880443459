/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  createComment,
  fetchCommentByProject,
  fetchCommentByProjectInfiniteScroll,
  postFiles,
} from './commentThunk';
import { toast } from 'react-toastify';

export interface CommentState {
  status: string;
  comments: [] | any | null;
  files: [] | any | null;
  meta: any;
  error: string | null;
  comment: {
    content: any | null;
    project: any | null;
    user: any | null;
  } | null;
  isLoading: boolean;
}

const initialState: CommentState = {
  status: 'idle',
  comments: [],
  files: [],
  comment: null,
  error: null,
  meta: null,
  isLoading: false,
};

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.comments = [];
    },
    restoreProject: (state) => {
      state.comment = initialState.comment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createComment.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(createComment.fulfilled, (state, action) => {
        const { payload } = action;
        state.comments = [...state.comments, payload.data];
        state.comment = {
          ...payload.data,
        };
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(createComment.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      })
      .addCase(fetchCommentByProject.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchCommentByProject.fulfilled, (state, action) => {
        state.comments = action.payload.results;
        state.meta = action.payload.next;
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(fetchCommentByProject.rejected, (state, action: PayloadAction<any>) => {
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      })
      .addCase(fetchCommentByProjectInfiniteScroll.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(fetchCommentByProjectInfiniteScroll.fulfilled, (state, action) => {
        state.comments = [...state.comments, ...action.payload.results];
        state.meta = action.payload.next;
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(
        fetchCommentByProjectInfiniteScroll.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action?.payload;
          state.status = 'failed';
          state.isLoading = false;
        }
      )
      .addCase(postFiles.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(postFiles.fulfilled, (state, action) => {
        state.files = [...state.files, action.payload];
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(postFiles.rejected, (state, action: PayloadAction<any>) => {
        toast.error(action?.payload.error);
        state.error = action?.payload;
        state.status = 'failed';
        state.isLoading = false;
      });
  },
});

export const { initialise, restoreProject } = commentSlice.actions;

export default commentSlice.reducer;
