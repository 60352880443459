import { useSelector } from 'react-redux';
import PublicCheckbox from './PublicCheckbox';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import { useParams } from 'react-router';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setEvaluationChanged } from '../../data/evaluationSlice';
import { getUserEvaluationById } from '../../data/evaluationThunk';

const PublicTeam = ({ selectedItem, setSelectedItem }: any) => {
  const { po_id, project_id } = useParams();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get('user_id');
  const token = searchParams.get('token');

  const { project, filterProjectType, evaluationChanged } = useSelector(
    (state: RootState) => state.evaluation
  );

  const [evaluated, setEvaluated] = useState([]);

  const isChecked = (id: any) => {
    return evaluated?.some((item: any) => item?.id === id);
  };

  const handleSelect = (member: any) => {
    if (user_id === String(member?.id)) {
      return;
    }

    if (evaluationChanged) {
      Swal.fire({
        title: 'You should save the changes first.',
        icon: 'warning',
      });
    } else {
      setSelectedItem(member?.id);
      setSearchParams({ ...Object.fromEntries(searchParams), user_id: String(member?.id) });

      if (token && String(member?.id) && project_id) {
        dispatch(
          getUserEvaluationById({
            token,
            user_id: String(member?.id),
            project_id,
          })
        );
      }
    }
  };

  return (
    <div className="bg-white rounded-lg overflow-hidden">
      {project?.members?.filter(
        (member: any) =>
          member.id !== po_id &&
          (filterProjectType === 'all' || member.departments.includes(filterProjectType))
      ).length === 0 && (
        <div className={`w-full py-3 px-2 bg-[#F4F6F8]`}>
          <div className="w-[250px] text-center">No Member Found!</div>
        </div>
      )}
      {project?.members
        ?.filter(
          (member: any) =>
            member.id !== po_id &&
            (filterProjectType === 'all' || member.departments.includes(filterProjectType))
        )
        ?.map((member: any, index: number) => (
          <div
            key={index}
            className={`w-full py-3 px-2 cursor-pointer hover:bg-[#919EAB29] ${
              selectedItem === member?.id ? 'bg-[#EEDEF4] hover:bg-[#EEDEF4]' : 'bg-[#fff]'
            }`}
            onClick={() => handleSelect(member)}
          >
            <div className="w-full flex justify-between items-center gap-2 min-w-[250px]">
              <div className="flex justify-center items-center gap-3">
                <img
                  src="/assets/icons/kpi/publickpi/11.png"
                  alt="avatar"
                  className="w-10 rounded-full"
                />
                <div className="">
                  <h3 className="text-sm font-bold text-nowrap">{member?.full_name}</h3>
                  <p className="text-xs font-normal text-nowrap">{member?.roles?.[0] || '-'}</p>
                </div>
              </div>
              <PublicCheckbox
                checked={isChecked(member?.id)}
                evaluated={evaluated}
                setEvaluated={setEvaluated}
                member={member}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default PublicTeam;
