export function formatResponse(res: any) {
  if (res?.[0]?.data?.[0]?.results?.length === 0) {
    return undefined;
  }

  let newRes: any = [];

  res?.forEach((item: any) => {
    newRes.push(item?.data?.[0]);
  });

  return newRes;
}
