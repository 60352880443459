interface ContentProps {
  header: string[];
  title: string;
  rows: string[][];
  setRows: any;
  result: string;
  edit: boolean;
}

interface CustomTableProps {
  content: ContentProps;
}

const CustomTableCard = ({ content }: CustomTableProps) => {
  const handleChange = (rowId: any, colId: any, newValue: any) => {
    // content?.setRows((prevItem: any) => {
    //   const newItem = [...prevItem];
    //   newItem[rowId][colId] = newValue.target.value;
    //   return newItem;
    // });
  };

  const handleDelete = (indexToDelete: any) => {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You won't be able to revert this!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!',
    // }).then((result: any) => {
    //   if (result.isConfirmed) {
    //     const firstArray = [...content?.rows[0]];
    //     const secondArray = [...content?.rows[1]];
    //     firstArray.splice(indexToDelete, 1);
    //     secondArray.splice(indexToDelete, 1);
    //     content?.setRows([firstArray, secondArray]);
    //   }
    // });
  };

  const handleCreate = () => {
    // const firstArray = [...content?.rows[0]];
    // const secondArray = [...content?.rows[1]];
    // if (firstArray[firstArray.length - 1] !== '' && secondArray[firstArray.length - 1] !== '') {
    //   firstArray.push('');
    //   secondArray.push('');
    // }
    // content?.setRows([firstArray, secondArray]);
  };

  return (
    <div className="overflow-hidden overflow-x-auto rounded-lg block justify-center items-center w-full">
      <div className="block rounded-t-lg">
        {/* body */}
        <div className="flex">
          <div className="w-full border-2 flex flex-col">
            <p
              className={`p-4 bg-[#F4F6F8] text-[#637381] w-full font-semibold text-sm border-b-2`}
            >
              {content?.header?.[0]}
            </p>
            <div className="flex justify-center items-center p-4 text-[#637381] w-full font-semibold text-base text-nowrap h-full">
              {content?.title}
            </div>
          </div>

          <div className="text-[#637381] font-medium text-sm border-r-2 border-t-2 border-b-2 w-full">
            <p className={`p-4 bg-[#F4F6F8] text-[#637381] font-semibold text-sm border-b-2`}>
              {content?.header?.[1]}
            </p>

            {content?.rows?.map((row: any, index: number) =>
              content?.edit ? (
                <input
                  type="text"
                  required
                  className={`w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  value={content?.rows?.[index]}
                  onChange={(e) => handleChange(0, index, e)}
                />
              ) : (
                <p
                  className={`text-[#212B36] text-xs font-medium p-4 text-nowrap ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  key={index}
                >
                  {row?.name}
                </p>
              )
            )}
          </div>

          <div className="w-full flex">
            <div className="text-[#637381] font-medium text-sm border-t-2 border-r-2 border-b-2 w-full">
              <div className="flex justify-between border-b-2 bg-[#F4F6F8]">
                <p className={`p-4 text-[#637381] font-semibold text-sm`}>{content?.header?.[2]}</p>

                {content?.edit && (
                  <div className="flex justify-center items-center px-2 border-l-2">
                    <img
                      src="/assets/icons/kpi/action.png"
                      alt="action"
                      className="w-[28px] h-[28px] object-none"
                    />
                  </div>
                )}
              </div>

              {content?.rows?.map((row: any, index: number) => (
                <div
                  className={`flex justify-between ${
                    index === content?.rows?.length - 1 ? '' : 'border-b-2'
                  }`}
                  key={index}
                >
                  {content?.edit ? (
                    <input
                      type="text"
                      required
                      className={`w-full block text-[#212B36] text-xs font-medium p-4 text-nowrap`}
                      value={content?.rows?.[index]}
                      onChange={(e) => handleChange(1, index, e)}
                    />
                  ) : (
                    <p className={`text-[#212B36] text-xs font-medium p-4 text-nowrap`}>
                      {row?.coefficient}
                    </p>
                  )}

                  {content?.edit && (
                    <div className="flex justify-center items-center px-2 border-l-2">
                      <img
                        src="/assets/icons/kpi/delete.png"
                        alt="action"
                        className="w-[28px] h-[28px] cursor-pointer"
                        onClick={() => handleDelete(index)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Add one */}
      {content?.edit && (
        <div className="flex justify-end w-full">
          <div
            className={`border border-t-0 rounded-b-lg overflow-hidden w-full flex justify-start items-center px-4 gap-3`}
          >
            <img
              src="/assets/icons/kpi/new.png"
              alt=""
              className="cursor-pointer"
              onClick={handleCreate}
            />
            <div
              className="py-3 text-[#637381] font-bold text-sm cursor-pointer"
              onClick={handleCreate}
            >
              Add New Skill
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex justify-end w-full">
        <div className={`w-full`}></div>
        <div className={`w-full`}></div>
        <div className={`border border-t-0 rounded-b-lg overflow-hidden w-full bg-[#FBFBFB]`}>
          <div className="py-3 px-4 text-[#637381] font-bold text-sm">{content?.result}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomTableCard;
