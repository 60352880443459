import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import CreateSkill from './CreateSkill';

const CreateCategory = ({ data, setData }: any) => {
  const handleChangeCategoryName = (e: any, index: number) => {
    setData((prevData: any) => ({
      ...prevData,
      categories: prevData.categories.map((item: any, i: number) => {
        if (i === index) {
          return { ...item, name: e.target.value };
        }
        return item;
      }),
    }));
  };

  const handleDelete = (categoryIndex: number) => {
    setData((prev: any) => {
      const newData = { ...prev };
      if (newData?.categories?.length === 1) {
        return prev;
      }
      newData.categories.splice(categoryIndex, 1);
      return newData;
    });
  };

  const handleCreate = (index: number) => {
    if (data?.categories?.find((category: any) => category?.name === '')) {
      toast.warn('Please fill in all categories');
      return;
    }

    const newCategory = {
      name: '',
      skills: [
        {
          name: '',
          coefficient: '',
        },
      ],
    };

    const updatedCategories = [...data.categories];

    updatedCategories.splice(index, 0, newCategory);
    setData((prevData: any) => ({
      ...prevData,
      categories: updatedCategories,
    }));
  };

  return (
    <div className="flex flex-col gap-8">
      {data?.categories?.map((item: any, index: number) => (
        <div className="flex flex-col gap-6" key={index}>
          <div className="border rounded-lg">
            <div className="p-10 pb-6 flex justify-center items-center gap-6">
              <TextField
                label="Category Name"
                variant="outlined"
                sx={{ width: '420px' }}
                onChange={(e) => handleChangeCategoryName(e, index)}
                value={item?.name}
              />
              <img
                src="/assets/icons/kpi/delete-btn.png"
                alt="remove"
                className={`cursor-pointer w-[50px] h-[50px] ${
                  data?.categories?.length === 1 ? 'cursor-not-allowed' : ''
                }`}
                onClick={() => handleDelete(index)}
              />
            </div>

            <div className="mb-10 mx-10 bg-[#F9FAFB] py-6 px-3 rounded-lg">
              <CreateSkill index={index} data={data} setData={setData} />
            </div>
          </div>

          <div>
            <button
              className="py-3 px-6 rounded-lg bg-[#919EAB14] flex justify-center items-center gap-2 font-bold"
              onClick={() => handleCreate(index + 1)}
            >
              <img src="/assets/icons/kpi/add.png" alt="add" />
              Create New Category
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CreateCategory;
