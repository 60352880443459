import { useSelector } from 'react-redux';
import { RootState } from 'src/modules/shared/store';

const PublicTotal = () => {
  const { template, evaluationData } = useSelector((state: RootState) => state.evaluation);

  function calculateTotalCoefficient() {
    let totalCoefficient = 0;
    template?.categories?.forEach((category: any) => {
      category?.skills?.forEach((skill: any) => {
        totalCoefficient += skill.coefficient;
      });
    });
    return parseFloat(totalCoefficient.toString()).toFixed(2);
  }

  function sumTotalNotes() {
    let total = 0;
    evaluationData?.forEach((category: any) => {
      category?.results?.forEach((skill: any) => {
        total += skill.score || 0;
      });
    });
    return parseFloat(total.toString()).toFixed(2);
  }

  return (
    <div className="w-full flex justify-end px-8 mb-16">
      <div className="flex justify-end w-full">
        <div className={`w-full`}></div>
        <div className={`w-full flex justify-end items-center`}>
          <h2 className="text-lg font-bold px-6">Total</h2>
        </div>
        <div className={`border overflow-hidden w-full bg-[#FBFBFB]`}>
          <div className="py-3 px-4 text-[#637381] font-bold text-sm">
            {calculateTotalCoefficient()}
          </div>
        </div>
        <div className={`border overflow-hidden w-full bg-[#FBFBFB]`}>
          <div className="py-3 px-4 text-[#637381] font-bold text-sm">{sumTotalNotes()}</div>
        </div>
      </div>
    </div>
  );
};

export default PublicTotal;
