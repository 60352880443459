import { Message } from '@mui/icons-material';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HOST_API } from 'src/config-global';
import axiosInstance from 'src/modules/shared/utils/axios';
import { IKanbanColumn } from 'src/types/kanban';

export const fetchKanban = createAsyncThunk('kanban/board', async (thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(
      `/status/overview-status?timestamp=${new Date().getTime()}`
    );
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const bulkUpdateProjects = createAsyncThunk(
  'bulk-update/board',
  async (data: {
    dragged_id: string;
    dragged_order: number;
    status: string;
    new_order: number;
  }) => {
    try {
      const response = await axiosInstance.put(`/projects/bulk-update`, data);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err.message ? err.message : "Can't update project";
      return Promise.reject(error);
    }
  }
);

export const bulkUpdateStatus = createAsyncThunk(
  'bulk-update/status',
  async (data: { dragged_id: string; new_order: number }) => {
    try {
      const response = await axiosInstance.put(`/status/bulk-update`, data);
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err.message ? err.message : "Can't update project";
      return Promise.reject(error);
    }
  }
);

export const createColumne = createAsyncThunk(
  'kanban/createColumn',
  async (columnData: IKanbanColumn, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        `${HOST_API}/status/`,
        {
          name: columnData.name,
          color: columnData.color,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      if (response.status === 200) {
        // Return the data if needed
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      // You can handle errors here and return an appropriate value
      return Promise.reject(err.message || 'Failed to create column');
    }
  }
);

export const deleteColumne = createAsyncThunk(
  'kanban/deleteColumn',
  async (columnId: string, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(`${HOST_API}/status/${columnId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      if (response.status === 204) {
        return columnId;
      }

      throw new Error(response.statusText);
    } catch (err) {
      // You can handle errors here and return an appropriate value
      return Promise.reject(err.message || 'Failed to delete column');
    }
  }
);
export const updateColumnName = createAsyncThunk(
  'ukanban/updateColumnName',
  async ({ columnId, newName }: { columnId: string; newName: string }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `${HOST_API}/status/${columnId}/`,
        {
          name: newName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      if (response.status === 200) {
        // Return the updated column data if needed
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      // Handle errors here and return an appropriate value
      return thunkAPI.rejectWithValue(err.message || 'Failed to update column name');
    }
  }
);
