import { sumCoefficient } from '../utils/sumCoefficient';
import CustomTableCard from './CustomTableCard';

const Preview = ({ data }: any) => {
  return (
    <div className="w-full border-2 rounded-xl pt-10 pb-3 px-8">
      <p className="font-bold text-lg text-[#212B36] mb-6">{data?.name}</p>

      {data?.categories?.map((category: any) => (
        <div className="mb-8">
          <CustomTableCard
            content={{
              title: category?.name,
              header: ['Category', 'Skills', 'Coefficient'],
              rows: category?.skills,
              setRows: null,
              result: sumCoefficient(category),
              edit: false,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default Preview;
