import { forwardRef } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
// routes
import { RouterLink } from 'src/modules/shared/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  sm?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sm = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo_wide = (
      <Box component="img" src="/logo/softylines.webp" sx={{ cursor: 'pointer', ...sx }} />
    );

    const logo_small = (
      <Box
        component="img"
        src="/logo/softylines_small_48.webp"
        sx={{ m: 0.5, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return sm ? logo_small : logo_wide;
    }

    return (
      <Link component={RouterLink} href="/">
        {sm ? logo_small : logo_wide}
      </Link>
    );
  }
);

export default Logo;
