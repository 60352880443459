/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axios';
import { rolePayload } from './rolesTypes';

export const fetchRoles = createAsyncThunk(
  'roles/fetchRoles',
  async ({ page = 1, page_size = 7 }: { page?: number; page_size?: number }) => {
    const res = await axiosInstance.get(`/roles/`, { params: { page, page_size } });

    const roles = res.data.results;
    const nbRoles = res.data.count;
    const next = res.data.next;
    const prev = res.data.prev;

    return { roles, nbRoles, next, prev };
  }
);

export const deleteRoles = createAsyncThunk('roles/deleteRoles', async (ids, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.delete(`/roles/`, {
      data: { ids },
    });
    data = await response.data;
    if (response.status === 200) {
      return { data, status: 'success' };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message,
    });
  }
});
export const fetchOneRole = createAsyncThunk('roles/fetchOneRole', async (id: string, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/roles/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const createRole = createAsyncThunk(
  'roles/createRole',
  async (payload: rolePayload, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/roles/`, payload);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
