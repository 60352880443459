import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { RootState, useAppDispatch } from 'src/modules/shared/store';
import Swal from 'sweetalert2';
import { setEvaluationChanged } from '../../data/evaluationSlice';
import { getUserEvaluationById } from '../../data/evaluationThunk';

const PublicFooter = ({ selectedItem, setSelectedItem }: any) => {
  const dispatch = useAppDispatch();
  const { po_id, project_id } = useParams();

  const { project, evaluationChanged } = useSelector((state: RootState) => state.evaluation);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  const checkIndex = (id: any) => {
    return project?.members
      ?.filter((member: any) => member.id !== po_id)
      .findIndex((item: any) => item.id === id);
  };

  const handlePrevious = () => {
    if (evaluationChanged) {
      Swal.fire({
        title: 'You should save the changes first.',
        icon: 'warning',
      });
    } else {
      const oldIndex = checkIndex(selectedItem);
      const selectedMember = project?.members?.filter((member: any) => member.id !== po_id);
      if (oldIndex > 0) {
        setSelectedItem(selectedMember?.[oldIndex - 1]?.id);
        if (token && String(selectedMember?.[oldIndex - 1]?.id) && project_id) {
          dispatch(
            getUserEvaluationById({
              token,
              user_id: String(selectedMember?.[oldIndex - 1]?.id),
              project_id,
            })
          );
        }
      }
    }
  };

  const handleNext = () => {
    if (evaluationChanged) {
      Swal.fire({
        title: 'You should save the changes first.',
        icon: 'warning',
      });
    } else {
      const oldIndex = checkIndex(selectedItem);
      const selectedMember = project?.members?.filter((member: any) => member.id !== po_id);
      if (oldIndex < selectedMember?.length - 1) {
        setSelectedItem(selectedMember?.[oldIndex + 1]?.id);
        if (token && String(selectedMember?.[oldIndex + 1]?.id) && project_id) {
          dispatch(
            getUserEvaluationById({
              token,
              user_id: String(selectedMember?.[oldIndex + 1]?.id),
              project_id,
            })
          );
        }
      }
    }
  };

  return (
    <div className="w-full flex justify-end items-center gap-4 border-t-2 py-4 px-8">
      <button
        className="py-2 px-4 rounded-lg border flex justify-center items-center gap-3"
        onClick={handlePrevious}
      >
        <img src="/assets/icons/kpi/publickpi/10.png" alt="na" />
        <p className="text-md font-bold">Previous</p>
      </button>

      <button
        className="py-2 px-4 rounded-lg border bg-[#592D8C] flex justify-center items-center gap-3"
        onClick={handleNext}
      >
        <p className="text-white text-md font-bold">Next Member</p>
        <img src="/assets/icons/kpi/publickpi/9.png" alt="na" />
      </button>
    </div>
  );
};

export default PublicFooter;
