/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getEvaluationProject,
  getEvaluationTemplate,
  getUserEvaluationById,
} from './evaluationThunk';
import { formatResponse } from '../utils/formatResponse';

export interface evaluationState {
  status: string;
  error: string | null;
  project: any;
  template: any;
  filterProjectType: any;
  evaluationData: any;
  evaluationChanged: boolean;
}

const initialState: evaluationState = {
  status: 'idle',
  error: null,
  project: null,
  template: null,
  filterProjectType: 'all',
  evaluationData: null,
  evaluationChanged: false,
};

const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    filterProjectMembers: (state, action) => {
      state.filterProjectType = action.payload;
    },
    setEvaluationData: (state, action) => {
      state.evaluationData = action.payload;
    },
    setEvaluationChanged: (state, action) => {
      state.evaluationChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvaluationProject.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getEvaluationProject.fulfilled, (state, action) => {
      state.project = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(getEvaluationProject.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(getEvaluationTemplate.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getEvaluationTemplate.fulfilled, (state, action) => {
      state.template = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(getEvaluationTemplate.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(getUserEvaluationById.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(getUserEvaluationById.fulfilled, (state, action) => {
      let data;
      data = action.payload?.criterias_data
        ? formatResponse(action.payload?.criterias_data)
        : undefined;

      if (!data) {
        data = state.template?.categories?.map((category: any) => ({
          results: category?.skills?.map((skill: any) => ({
            skill_id: skill.id,
            score: 0,
          })),
        }));
      }

      state.evaluationData = data;
      state.status = 'succeeded';
    });
    builder.addCase(getUserEvaluationById.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
  },
});

export const { filterProjectMembers, setEvaluationData, setEvaluationChanged } =
  evaluationSlice.actions;

export default evaluationSlice.reducer;
