/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchOneStatus,
  createStatus,
  bulkUpdateStatus,
  fetchStatus,
  deleteStatus,
  updateOneStatus,
} from './statusThunk';
import { toast } from 'react-toastify';

export interface StatusState {
  state_status: string;
  statuses: Array<any> | any | null;
  nbStatuses: number | null;
  next: number | null;
  prev: number | null;
  error: string | null;
  status: {
    name: string | null;
    color: string;
    order: number;
  } | null;
}

const initialState: StatusState = {
  state_status: 'idle',
  statuses: [],
  nbStatuses: null,
  next: null,
  prev: null,
  status: null,
  error: null,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.statuses = [];
    },
    restoreStatus: (state) => {
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(fetchStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.statuses = action.payload.statuses;
      state.nbStatuses = action.payload.nbStatuses;
      state.next = action.payload.next;
      state.prev = action.payload.prev;
      state.state_status = 'succeeded';
    });
    builder.addCase(fetchStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.state_status = 'failed';
    });

    builder.addCase(fetchOneStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(fetchOneStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = action.payload;
      state.state_status = 'succeeded';
    });
    builder.addCase(fetchOneStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.state_status = 'failed';
    });
    builder.addCase(createStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(createStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.statuses = state.statuses && [...state.statuses, action.payload];
      state.state_status = 'succeeded';
    });
    builder.addCase(createStatus.rejected, (state, action: PayloadAction<any>) => {
      toast.error('name already exist');
      state.error = action?.payload;
      state.state_status = 'failed';
    });
    builder.addCase(deleteStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(deleteStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.statuses =
        state.statuses && state.statuses.filter((status: any) => status.id != action.payload.id);
      state.state_status = 'succeeded';
    });
    builder.addCase(deleteStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.state_status = 'failed';
    });
    builder.addCase(bulkUpdateStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(bulkUpdateStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.state_status = 'succeeded';
    });
    builder.addCase(bulkUpdateStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.state_status = 'failed';
    });
    builder.addCase(updateOneStatus.pending, (state) => {
      state.error = null;
      state.state_status = 'loading';
    });
    builder.addCase(updateOneStatus.fulfilled, (state, action: PayloadAction<any>) => {
      state.statuses = state.statuses?.map((state: any) => {
        if (state.id === action.payload.id) {
          return { ...state, color: action.payload.color };
        } else {
          return state;
        }
      });
      state.state_status = 'succeeded';
    });
    builder.addCase(updateOneStatus.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.state_status = 'failed';
    });
  },
});

export const { initialise, restoreStatus } = statusSlice.actions;

export default statusSlice.reducer;
