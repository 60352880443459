import { lazy, Suspense } from 'react';

import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/modules/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/modules/shared/components/loading-screen';
import KpiDetails from '../features/KpiDetails';
import Template from '../features/Template';
import PublicKpi from '../features/PublicKpi';
import Evaluation from '../features/Evaluation';

// ----------------------------------------------------------------------
const KpiView = lazy(() => import('../features/Kpi'));

// ----------------------------------------------------------------------

export const kpiRoutes = [
  {
    path: 'kpi',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <KpiView />, index: true }],
  },
  {
    path: 'kpi/details',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <KpiDetails />, index: true }],
  },
  {
    path: '/template',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <Template />, index: true }],
  },
  // {
  //   path: '/evaluation',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout>
  //         <Suspense fallback={<LoadingScreen />}>
  //           <Outlet />
  //         </Suspense>
  //       </DashboardLayout>
  //     </AuthGuard>
  //   ),
  //   children: [{ element: <Evaluation />, index: true }],
  // },
  {
    path: 'kpi/project_id/:project_id/po_id/:po_id/template_id/:template_id/',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ element: <PublicKpi />, index: true }],
  },
];
