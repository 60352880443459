/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorResponse } from '@remix-run/router';
import axiosInstance from '../../shared/utils/axios';
import { toast } from 'react-toastify';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({
    page = 1,
    page_size = 7,
    disableCache = false,
    name,
  }: {
    name?: string;
    page?: number;
    page_size?: number;
    disableCache?: boolean;
  }) => {
    const headers = disableCache ? { 'Cache-Control': 'no-cache' } : {};
    const res = await axiosInstance.get(`/user/users`, {
      params: { page, page_size, name },
      headers,
    });

    const users = res.data.data.results.map((user: any) => {
      const formattedUser = { ...user };
      formattedUser['fullName'] = formattedUser['full_name'];
      delete formattedUser['full_name'];
      formattedUser['avatarUrl'] = formattedUser['photo'];
      delete formattedUser['photo'];
      formattedUser['activeProjects'] = formattedUser['projects'];
      delete formattedUser['projects'];
      return formattedUser;
    });
    const nbUsers = res.data.data.count;
    const next = res.data.data.next;
    const prev = res.data.data.prev;
    return { users, nbUsers, next, prev };
  }
);

export const deleteUsers = createAsyncThunk('users/deleteUsers', async (ids: any, thunkAPI) => {
  let data: any;
  try {
    const deletePromises = ids?.map(async (id: any) => {
      try {
        await axiosInstance.delete(`/user/${id}`);
      } catch (error) {
        return Promise.reject(error);
      }
    });

    try {
      await Promise.all(deletePromises);
    } catch (error) {
      return Promise.reject(error);
    }

    return { data, status: 'success' };
  } catch (err) {
    return Promise.reject({
      message: err.message ? err.message : data?.message,
    });
  }
});
export const deleteOneUser = createAsyncThunk(
  'projects/deleteOneUser',
  async (id: any, thunkAPI) => {
    let data: any;

    try {
      await axiosInstance.delete(`/user/${id}`);

      return { id, status: 'success' };
    } catch (err) {
      return Promise.reject({
        message: err.message ? err.message : data?.message,
      });
    }
  }
);

export const filterUsers = createAsyncThunk('user/filterUsers', async (name: string, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/user/users?name=${name}`);

    data = response.data;
    if (response.status === 200) {
      return data.data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const fetchOneUser = createAsyncThunk('user/fetchOneUser', async (id: string, thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/user/${id}`);

    data = response.data;
    if (response.status === 200) {
      return data.data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload: FormData, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`user/users`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data = await response.data;
      if (response.status === 201) {
        return data;
      }

      throw new Error(response?.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload: FormData, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.patch(`user/${payload.get('id')}`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      data = await response.data;
      if (response.status === 200 || response.status === 201) {
        return data;
      }

      throw new Error(response?.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err as ErrorResponse);
    }
  }
);

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async (thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/user/me`);
    data = await response.data;

    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
