export function sumCoefficient(obj: any) {
  let sum = 0;
  obj?.skills?.forEach((skill: any) => {
    sum += parseFloat(skill?.coefficient || 0);
  });
  return String(sum);
}

export function sumNotes(obj: any) {
  let sum = 0;
  obj?.results?.forEach((skill: any) => {
    sum += parseFloat(skill?.score || 0);
  });
  return String(sum);
}

export function hasNullScore(data: any) {
  for (let i = 0; i < data.length; i++) {
    const results = data?.[i]?.results;

    for (let j = 0; j < results.length; j++) {
      if (results[j].score === null || Number.isNaN(results[j].score)) {
        return true;
      }
    }
  }

  return false;
}
