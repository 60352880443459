/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../modules/shared/utils/axios';

export const createFile = createAsyncThunk(
  'files/createFile',
  async (payload: FormData, thunkAPI) => {
    let data;
    try {
      const response = await axiosInstance.post(`/files/`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      data = await response.data;

      return data;
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchFiles = createAsyncThunk('files/fetchFiles', async (thunkAPI) => {
  let data;
  try {
    const response = await axiosInstance.get(`/files/`);
    data = await response.data;

    return data;
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
