import { useState } from 'react';
import CreateCategoryModal from './CreateCategoryModal';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/modules/shared/store';
import { useSearchParams } from 'react-router-dom';
import { createCategory, createNewSkill } from '../data/templateThunk';
import CloseIcon from '../../../assets/icons/close.svg';
const CreateCategoryCard = ({ setOpen }: any) => {
  const dispatch = useAppDispatch();
  const [searchParams, _setSearchParams] = useSearchParams();
  const template_id = searchParams.get('id');

  const [data, setData] = useState({
    name: '',
    skills: [
      {
        name: '',
        coefficient: '',
      },
    ],
  });

  const handleSubmit = () => {
    if (data?.name === '') {
      toast.warn('Please fill in the name');
      return;
    }

    if (data?.skills?.some((item: any) => item?.name === '' || item?.coefficient === '')) {
      toast.warn('Please fill in all skills');
      return;
    }

    dispatch(
      createCategory({
        categories_data: [
          {
            name: data?.name,
            template: template_id,
          },
        ],
      })
    )
      .unwrap()
      .then((res) => {
        const addIdToSkills = (skills: any, category_id: any) =>
          skills.map((skill: any) => ({ ...skill, category: category_id }));

        const categoryList = addIdToSkills(data?.skills, res?.[0]?.id);

        dispatch(
          createNewSkill({
            criterias_data: categoryList,
          })
        );
      });
  };

  return (
    <div>
      <div className="py-4 px-8 flex justify-between">
        <div className="flex items-center gap-4">
          <img src="/assets/icons/kpi/createCategory.png" alt="check" />
          <h1 className="text-lg font-bold">Create New Category</h1>
        </div>
        <img
          src={CloseIcon}
          alt="check"
          className="cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>

      <CreateCategoryModal data={data} setData={setData} />

      <div className="flex justify-end gap-3 py-4 px-8">
        <button className="py-2 px-6 border rounded-lg" onClick={() => setOpen(false)}>
          Cancel
        </button>

        <button
          className="py-2 px-6 border rounded-lg bg-[#592D8C] text-white flex items-center gap-2"
          onClick={handleSubmit}
        >
          <img src="/assets/icons/kpi/check.png" alt="check" />
          Create Category
        </button>
      </div>
    </div>
  );
};

export default CreateCategoryCard;
