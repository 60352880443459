import { useNavigate } from 'react-router';

import React from 'react';
import { useAppSelector } from '../../shared/store';

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);

  if (isAuthenticated) {
    navigate('/');
  }

  return <>{children}</>;
}
