// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// ----------------------------------------------------------------------
//import 'react-quill/dist/quill.snow.css';
// routes
import Router from 'src/modules/shared/routes';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/modules/shared/hooks/use-scroll-to-top';
// components
import { MotionLazy } from 'src/modules/shared/components/animate/motion-lazy';
import ProgressBar from 'src/modules/shared/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/modules/shared/components/settings';
// auth
import AuthProvider from 'src/modules/auth/context/AuthProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'green' | 'blue' | 'orange' | 'red'
          themeStretch: false,
          openModal: false,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider>
            <MotionLazy>
              <SettingsDrawer />
              {/* <ProgressBar /> */}

              <Router />
            </MotionLazy>
          </ThemeProvider>
        </LocalizationProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
