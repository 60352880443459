/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOneType, fetchTypes } from './typeThunk';

export interface TypesState {
  status: string;
  types: [] | null;
  nbTypes: number | null;
  next: number | null;
  prev: number | null;
  error: string | null;
  type: {
    name: string | null;
  } | null;
}

const initialState: TypesState = {
  status: 'idle',
  types: [],
  nbTypes: null,
  next: null,
  prev: null,
  type: null,
  error: null,
};

const typeSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    initialise: (state, action) => {
      state.types = [];
    },
    restoreType: (state) => {
      state.type = initialState.type;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTypes.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchTypes.fulfilled, (state, action: PayloadAction<any>) => {
      state.types = action.payload.types;
      state.status = 'succeeded';
    });
    builder.addCase(fetchTypes.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });

    builder.addCase(fetchOneType.pending, (state) => {
      state.error = null;
      state.status = 'loading';
    });
    builder.addCase(fetchOneType.fulfilled, (state, action: PayloadAction<any>) => {
      state.type = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchOneType.rejected, (state, action: PayloadAction<any>) => {
      state.error = action?.payload;
      state.status = 'failed';
    });
  },
});

export const { initialise, restoreType } = typeSlice.actions;

export default typeSlice.reducer;
